<template>
  <fp-panel-card :title="$t('healthProfessional.home.projectProgress')">
    <template #content>
      <div v-if="project">
        <div class="eh-progress-text"></div>
        <b>{{ getTextByLanguage(project.Research.Name) }}</b>
        <div v-if="projectProgress" class="eh-progress-text">
          {{ $t("healthProfessional.home.timeProgress") }}:
          <strong
            >{{
              (
                (projectProgress.DaysPassed / projectProgress.TotalDays) *
                100
              ).toFixed(0)
            }}%</strong
          >
        </div>
        <b-progress
          v-if="projectProgress"
          height="40px"
          :value="projectProgress.DaysPassed"
          :max="projectProgress.TotalDays"
        />
        <div
          v-if="projectProgress"
          style="text-align: center; margin-top: 10px"
        >
          {{ $t("healthProfessional.home.doctorProgress") }}:
          <strong>{{ (projectProgress.OwnProgress * 100).toFixed(0) }}%</strong>
        </div>
        <b-progress
          v-if="projectProgress"
          height="40px"
          :value="projectProgress.OwnProgress"
          :max="1"
        />
      </div>
    </template>
  </fp-panel-card>
</template>
<script>
import { ProjectLogic } from "../../Logic/Backend/project";
export default {
  name: "ProjectProgress",
  data() {
    return {
      projectProgress: null,
      //tesztelési változó
      currentProjectProgress: Math.random() * 100,
    };
  },
  props: {
    project: Object,
  },
  watch: {
    async project() {
      await this.getProjectProgress();
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    async getProjectProgress() {
      const ownParticipantId = this.getOwnParticipantId();
      const getProjectProgressResponse = await ProjectLogic.getProjectDoctorProgress(
        this.project.ProjectId,
        ownParticipantId
      );
      if (!getProjectProgressResponse.Code) {
        this.projectProgress = getProjectProgressResponse;
      } else {
        this.$bvToast.toast(getProjectProgressResponse.Message, {
          title: this.$t("requestResponse.project.errorGetProgress"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    getOwnParticipantId() {
      var temp = null;
      if (this.project) {
        temp = this.project.Participants.find(
          (p) => p.UserId == this.$loggedUser.UserId
        );
      }
      return temp ? temp.UserId : null;
    },
  },
  async mounted() {
    this.project && this.$loggedUser.SelectedProject
      ? await this.getProjectProgress()
      : "";
  },
};
</script>
